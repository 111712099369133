<template>
  <mobile-screen :header="true" screen-class="icon-app1 gray-bg logs-screen">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("menu", "company-admin", "log-files") }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input
                  id="isActive"
                  type="checkbox"
                  ref="isActiveToggle"
                  :checked="activeState"
                  @change="toggleIsActive"
                />
                <label for="isActive"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ displayLabelName("logs", "logs", "is-active") }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>

    <ul class="clebex-item-section pill">
      <li
        v-for="(log, index) in logsList"
        :key="index"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-men1-download-sample"
                @click="triggerLogDownload(log.name)"
              ></icon>
            </span>
          </span>
          <span class="label">
            <span class="highlight">{{ log.name }}</span>
          </span>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="logs-footer-menu actions-menu theme-gray">
        <ul class="menu-items">
          <li class="menu-item central-item icon-foo2">
            <download-log-button @click="triggerCurrentMonthLogDownload" />
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import DownloadLogButton from "@/components/logs/DownloadLogButton";
import { mapState, mapActions } from "vuex";

export default {
  name: "Logs",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    DownloadLogButton
  },
  created() {
    this.getActiveState();
    this.getLogsList();
  },
  computed: {
    ...mapState("logs", ["logsList", "activeState"])
  },
  methods: {
    ...mapActions("logs", [
      "getLogsList",
      "getActiveState",
      "toggleActiveLogs",
      "downloadLog",
      "downloadCurrentMonthLog"
    ]),
    toggleIsActive() {
      const isChecked = this.$refs.isActiveToggle.checked ? 1 : 0;
      this.toggleActiveLogs(isChecked);
    },
    triggerLogDownload(logName) {
      this.downloadLog(logName);
    },
    triggerCurrentMonthLogDownload() {
      this.downloadCurrentMonthLog();
    }
  }
};
</script>
